<template>
  <t-body class="account-page">
    <t-sidebar>
      <div class="intro">
        <div>
          <h4 class="intro__welcome">
            {{ $t('fields.welcome'), }}
          </h4>
          <h3 class="intro__name">
            {{ user.fullname }}
          </h3>
        </div>
      </div>
    </t-sidebar>
    <t-page>
      <h3 slot="header">
        {{ $t('global.headings.dashboard') }}
      </h3>
      <t-grid>
        <t-grid-row>
          <t-grid-cell>
            <widget-employee-count />
          </t-grid-cell>
          <t-grid-cell>
            <widget-organization-count />
          </t-grid-cell>
          <t-grid-cell>
            <widget-applicant-count />
          </t-grid-cell>
        </t-grid-row>
      </t-grid>
    </t-page>
  </t-body>
</template>

<script>
import Vue from 'vue';
import WidgetEmployeeCount from '~/pages/shared/dashboard/partials/WidgetEmployeeCount';
import WidgetOrganizationCount from '~/pages/shared/dashboard/partials/WidgetOrganizationCount';
import WidgetApplicantCount from '~/pages/shared/dashboard/partials/WidgetApplicantCount';

export default Vue.extend({
  components: {
    WidgetEmployeeCount,
    WidgetOrganizationCount,
    WidgetApplicantCount,
  },

  computed: {
    me() {
      return this.$auth.me;
    },

    user() {
      return this.me.user;
    },
  },
});
</script>

<style lang="scss" scoped>
.t-sidebar-dashboard {
  .t-sidebar {
    // Weird bug with the t-menu causing scroll.
    overflow-y: visible;
  }
}
.intro {
  margin-top: $s-8;
  margin-bottom: $s-16;

  .organization-logo {
    margin-bottom: $s-4;
  }

  &__welcome {
    font-weight: 300;
    font-size: $font-size-2xl;
  }

  &__name {
    font-size: $font-size-3xl;
  }
}

.section {
  &__title {
  }
}
</style>
